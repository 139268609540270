<template>
  <div id="search-view">
    <div class="search-view-fixed fixed-top">
      <div class="search-view-header">
        <div class="search-view-container">
          <div class="row search-view-container_header align-items-center">
            <div class="col-12 col-md-8">
              <h2>{{ $t("dashboard.integrado.title") }}</h2>
            </div>
            <div class="col-12 col-md-4 pb-5 pb-md-0 text-right">
              <b-button
                @click="MOSTRAR_MODAL_CESTA(true)"
                icon="icon-shopping-cart-outline"
                iconPosition="left"
                style="background-color: #00478b"
                >Mi cesta
                <span
                  style="
                    margin-left: 10px;
                    color: #005bbb;
                    height: 19px;
                    width: 19px;
                    padding: 3px;
                    background-color: #ffff;
                    border-radius: 50%;
                    display: inline-block;
                  "
                  >{{ cesta.items.length }}</span
                ></b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="search-view-filters">
        <div class="search-view-container">
          <div class="search-background m-0">


            <div class="search-select-input px-1">
              <input-custom
                placeholder="Busca mediante un CN o nombre"
                @ON_EVENT_BUTTON="realizarBusqueda()"
                v-model="termino_busqueda"
                icon="icon-search-outline"
              />
              <b-button class="boton" @click="realizarBusqueda()"
                >Buscar</b-button
              >
            </div>

              <div
        class="my-3"
        v-if="this.prioridades.length === 0 && !loading_prioridades"
      >
        <toast
          type="error-filled"
          title="Error en prioridades"
          description="No existen prioridades asignadas para este tramo horario, no podrá introducir artículos en la cesta"
          @close="cerrarAvisoPrioridades"
        />
      </div>

              <div
              v-if="mostrar_resultados && productos_total > 0"
              class="w-100 d-flex justify-content-start mt-3"
            >
              <select-custom-simple
                label="Ordenar por"
                :options="opciones_orden"
                @select-opcion="(filtro) => realizarBusqueda(1, filtro)"
              />
            </div>



          </div>
        </div>
      </div>
    </div>

    <div class="container">

      <div v-if="mostrar_resultados" class="row">
        <div class="col-12">
          <span v-if="productos_total > 0">
            <!--Se han encontrado {{ productos_total }} artículos-->
          </span>
          <span v-else>
            <div
              class="d-flex justify-content-center"
              style="margin-top: 250px"
            >
              <div class="row">
                <div class="col-md-12 text-center">
                  <span style="font-size: 90px" class="icon-search-outline" />
                </div>
                <div class="col-md-12 m-3 text-center">
                  <strong>No hay resultados</strong>
                </div>
                <div class="col-md-12 text-center">
                  Parece que no hay resultados para los criterios de búsqueda
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="search-view-results">
      <div class="search-view-container mb-3">
        <template
          v-if="mostrar_resultados && productos_cooperativas_sirve.length > 0"
        >
          <div
            class="mt-3 mb-3"
            v-for="producto in productos_cooperativas_sirve"
            :key="producto.codigo_nacional"
          >
            <card-search-product :product="producto" />
          </div>
        </template>
        <div class="d-flex justify-content-center">
          <img
            v-if="loading"
            class="loading-icon"
            src="@/assets/loading.gif"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center mt-3"
      v-if="mostrar_resultados && this.productos_total > this.page_size"
    >
      <Pagination
        :rows="this.productos_total"
        :perPage="this.page_size"
        :value="this.pagina_actual"
        @input="paginacion"
      />
    </div>
    <modal-cesta v-if="modal_cesta.value" @abrir-modal-pedido="ABRIR_MODAL_PEDIDO(true)" @cerrar-modal="MOSTRAR_MODAL_CESTA(false)"/> <!-- @detalle-pedido="detallePedido(false)" -->
    <modal-pedido v-if="modal_pedido" :pedido="pedido.pedido_realizado.pedido" @cerrar-modal="cerrarModalPedido()"/>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";
import buscador from "@/api/buscador";
import ModalCesta from "@/components/integrado/modal/ModalCesta.vue";
import CardSearchProduct from "@/components/search/card/CardSearchProduct.vue";
import utilities from "@/mixins/utilities";
import userValidations from "@/mixins/userValidations";
import Cesta from "../../store/cesta/models/cesta";
import { mapState, mapActions, mapMutations } from "vuex";
//import {commit} from "lodash/seq";
import ModalPedido from "@/components/integrado/modal/ModalPedido";

export default {
  name: "buscador",
  mixins: [utilities, userValidations],
  extends: buscador,
  components: { CardSearchProduct, ModalCesta, ModalPedido },
  data() {
    return {
      termino_busqueda: "",
      opciones_orden: [
        {
          text: "Código",
          value: "codigo_nacional",
          selected: false,
        },
        {
          text: "Descripción",
          value: "descripcion",
          selected: false,
        },
        {
          text: "Plazo de entrega",
          value: "plazo_entrega",
          selected: false,
        }
      ],
      mostrar_error_prioridades: true,
      pagina_actual: 0,
      productos_total: 0,
      page_size: 0,
      filtro: "",
      loading: false,
      loading_prioridades: false,
      mostrar_prioridades: false,
      mostrar_resultados: false,
      prioridades: [],
      existencias: {},
      productos: [],
      modal_pedido: false
    };
  },
  created() {
    this.loading_prioridades = true;
    httpClient.get(`prioridades`).then((response) => {
      this.prioridades = response.data.prioridades;
      this.mostrar_prioridades = true;
      this.loading_prioridades = false;
    });
    if (this.codigo_articulo) {
      this.termino_busqueda = this.codigo_articulo;
      setTimeout(() => {
          this.realizarBusqueda();
      }, "2000");
    }
    this.comprobarCesta();

  },
  beforeMount(){
    
  },
  computed: {
    ...mapState(["user", "codigo_articulo"]),
    ...mapState("cesta", ["cesta", "modal_cesta", "pedido"]),
    productos_cooperativas_sirve() {
      let productos_existencias = this.productos.map((item) => {
        return {
          id: item.id,
          codigo_nacional: item.codigo_nacional,
          descripcion: item.descripcion,
          pvl: item.pvl,
          pvu: item.pvu,
          imagen2d: item.imagen2d,
          config_cooperativas: item.config_cooperativas,
          variantes: item.variantes,
          ...this.existencias[item.codigo_nacional],
        };
      });

      let arr_prods = [];
      for (let i = 0; i < productos_existencias.length; i++) {
        const articulo = {
          id: this.productos[i].id,
          codigo_nacional: this.productos[i].codigo_nacional,
          descripcion: this.productos[i].descripcion,
          pvl: this.productos[i].pvl,
          pvu: this.productos[i].pvu,
          imagen2d: this.productos[i].imagen2d,
          config_cooperativas: this.productos[i].config_cooperativas,
          coop_sirve: null,
          sirve: " - ",
          plazo: {
            id: "",
            texto: "Sin existencias",
          },
          variantes: this.productos[i].variantes,
          prioridades: [],
          cantidad: 1,
        };
        let sirve = false;
        if (true || process.env.NODE_ENV === "development")
          console.log("------------------------------");
        if (true || process.env.NODE_ENV === "development")
          console.log(`--- ARTICULO ${this.productos[i].codigo_nacional} ---`);

        if (true || process.env.NODE_ENV === "development")
          console.log("--- PRIORIDADES ---");
        let pide_principal = false;

        for (let j = 0; j < this.prioridades.length; j++) {
          //No rebota de forma global 99 - 99
          let exclusion_global = this.exclusionGlobal(
            this.productos[i].excluidos_integrado
          );
          /*let exclusion_farmacia = this.exclusionFarmacia(
            this.prioridades[j],
            this.productos[i].excluidos_integrado
          );*/
          let exclusion_rebote = this.exclusionRebote(
            this.prioridades[j],
            this.productos[i].excluidos_integrado
          );

          //console.log(`${exclusion_global} ${this.user.cooperativa.id_unnefar}  - ${this.prioridades[j].almacen.cooperativa.id_unnefar}`);

          let mirar_existencias = true;

          //Si la cooperativa es distinta a la del usuario se aplican las restricción de productos
          if (this.prioridades[j].principal!="1") {
            if (exclusion_global) {
              if (true || process.env.NODE_ENV === "development")
                console.log(
                  `${this.prioridades[j].prioridad} - ${this.prioridades[j].almacen.cooperativa.nombre} (${this.prioridades[j].almacen.nombre}) Restringido total rebotes`
                );
              if (!pide_principal)
                articulo.plazo.texto = exclusion_global.descripcion_falta
                  ? exclusion_global.descripcion_falta
                  : "Restringido total rebotes";
              mirar_existencias = false;
            }
            /*else if (exclusion_farmacia) {
              if (true || process.env.NODE_ENV === "development")
                console.log(
                  `${this.prioridades[j].prioridad} - ${this.prioridades[j].almacen.cooperativa.nombre} (${this.prioridades[j].almacen.nombre}) Restringido por farmacia`
                );
              if (!pide_principal)
                articulo.plazo.texto = exclusion_farmacia.descripcion_falta
                  ? exclusion_farmacia.descripcion_falta
                  : "Restringido por farmacia";
              mirar_existencias = false;
            }*/
            else if (exclusion_rebote) {
              if (true || process.env.NODE_ENV === "development")
                console.log(
                  `${this.prioridades[j].prioridad} - ${this.prioridades[j].almacen.cooperativa.nombre} (${this.prioridades[j].almacen.nombre})  NO REBOTA`
                );
              //if(!pide_principal) articulo.plazo.texto=exclusion_farmacia.descripcion_falta ? exclusion_farmacia.descripcion_falta : "Restringido por rebote";
              mirar_existencias = false;
            }
          }

          if (mirar_existencias) {
            //let cooperativa_sirve= this.isCofas() ? `sirve ${this.prioridades[j].almacen.cooperativa.nombre}`  : '';
            let existe =
              productos_existencias[i][this.prioridades[j].almacen.id];
            if (!sirve && existe) {
              articulo.sirve = `${this.prioridades[j].almacen.nombre} (${this.prioridades[j].almacen.cooperativa.nombre})`;
              articulo.coop_sirve = this.prioridades[j].almacen.cooperativa.id_unnefar;
              articulo.plazo = {
                id: this.prioridades[j].plazo_entrega,
                texto: `${this.prioridades[j].texto_respuesta_pedido}`,
              };
              sirve = true;
            }
            if (true || process.env.NODE_ENV === "development")
              console.log(
                `${this.prioridades[j].prioridad} - ${
                  this.prioridades[j].principal == "1" ? "Principal" : ""
                } - ${this.prioridades[j].almacen.cooperativa.nombre} - PLAZO ${this.prioridades[j].plazo_entrega.substr(0,1)} - (${
                  this.prioridades[j].almacen.nombre
                }) REBOTA`,
                ` EXISTENCIA ${existe ? "Si" : "No"}`
              );
            articulo.prioridades.push({
              almacen: this.prioridades[j].almacen.nombre,
              cooperativa: this.prioridades[j].almacen.cooperativa.nombre,
              existe: existe,
              prioridad: this.prioridades[j].prioridad,
              plazo: existe
                ? `${this.prioridades[j].texto_respuesta_pedido}`
                : `-`,
            });
            if (
              this.user.cooperativa.id_unnefar ==
              this.prioridades[j].almacen.cooperativa.id_unnefar
            ) {
              pide_principal = true;
            }
          }
        }

        if (true || process.env.NODE_ENV === "development")
          console.log(
            `SIRVE ${articulo.sirve} PLAZO ${articulo.plazo.id} - ${articulo.plazo.texto}`
          );
        if (true || process.env.NODE_ENV === "development")
          console.log("------------------------------");
        arr_prods.push(articulo);
      }
      if(this.filtro === 'plazo_entrega'){
        arr_prods.sort(function (a,b){
          let plazo1 = a.plazo.id==''? 'Z' : a.plazo.id;
          let plazo2 = b.plazo.id==''? 'Z' : b.plazo.id;
          return plazo1.localeCompare(plazo2) || a.prioridades[0].prioridad - b.prioridades[0].prioridad;
        });
      }
      console.log(arr_prods);
      return arr_prods;
    },
  },
  methods: {
    ...mapActions("cesta", ["MOSTRAR_MODAL_CESTA", "COMPROBAR_ESTADO_CESTA"]),
    comprobarCesta() {
      this.COMPROBAR_ESTADO_CESTA(true);
    },
    realizarBusqueda(page = 1, filtro = null) {
      this.mostrar_resultados = false;
      this.loading = true;
      this.filtro = filtro;
      console.log("busqueda", this.termino_busqueda)
      httpClient
        .post(`busqueda`, {
          termino_busqueda: this.termino_busqueda,
          page: page,
          orden: filtro,
        })
        .then((response) => {
          this.page_size = filtro=="plazo_entrega"? response.data.productos.total : response.data.productos.per_page;
          this.pagina_actual = response.data.productos.current_page;
          this.productos = response.data.productos.data;
          this.productos_total = response.data.productos.total;
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
         // if (this.productos.length) {
            this.crearTablaExistencias();
            this.getExistencias();
         /* } else {
            this.mostrar_resultados = true;
            this.loading = false;
          }*/
        });
    },
    crearTablaExistencias() {
      let existencias = {};
      for (let i = 0; i < this.productos.length; i++) {
        //Creo un objecto para productos
        existencias[this.productos[i].codigo_nacional] = {
          descripcion: this.productos[i].descripcion,
        };
        for (let j = 0; j < this.prioridades.length; j++) {
          existencias[this.productos[i].codigo_nacional][
            this.prioridades[j].almacen.id
          ] = false;
        }
      }
      this.existencias = existencias;
    },
    getExistencias() {
      let productos = this.productos.map((item) => item.id);

      let arr_peticiones = [];
      for (let i = 0; i < this.prioridades.length; i++) {
        arr_peticiones.push(
          httpClient.post(`existencias`, {
            ids_productos: productos,
            id_almacen: this.prioridades[i].almacen.id,
            id_prioridad: this.prioridades[i].id
          })
        );
      }

      Promise.all(arr_peticiones).then((resultados) => {
        for (let resultado_peticion in resultados) {
          this.asignarExistencias(resultados[resultado_peticion].data);
        }
        this.loading = false;
        this.mostrar_resultados = true;
      });
    },
    asignarExistencias(data) {
      let existencias_cooperativa = data.existencias;
      for (let existencia in existencias_cooperativa) {
        if (this.existencias.hasOwnProperty(existencia)) {
          this.existencias[existencia][data.id_almacen] =
            existencias_cooperativa[existencia];
        }
      }
    },
    seleccionarFiltro(e) {
      this.filtro = e.value;
      this.productos = _.sortBy(this.productos, this.filtro);
    },
    plazo(p) {
      const plazos = {
        A: "Siguiente reparto",
        B: "Entregamos en 24 horas",
        C: "Entregamos en 48 horas",
        D: "Entregamos en más de 48 horas",
      };
      for (let param in plazos) {
        if (plazos[param] === p) {
          return param;
        }
      }
    },
    paginacion(page) {
      this.realizarBusqueda(page);
    },
    cerrarAvisoPrioridades() {
      this.mostrar_error_prioridades = false;
    },
    exclusionGlobal(exclusiones) {
      return exclusiones.find(
        (item) =>
          item.cooperativa == "99" &&
          item.cooperativa_no_rebota == "1" &&
          item.farmacia_no_rebota == "1"
      );
    },
    exclusionFarmacia(prioridad, exclusiones) {
      return exclusiones.find(
        (item) =>
          item.cooperativa == prioridad.almacen.cooperativa.id_unnefar &&
          item.farmacia_no_rebota == "1"
      );
    },
    exclusionRebote(prioridad, exclusiones) {
      //console.log("prioirdad",prioridad);console.log("exc",exclusiones)
      return exclusiones.find((item) => {
        //console.log(item.cooperativa+" - "+prioridad.almacen.cooperativa.id_unnefar+" - "+item.cooperativa_no_rebota);
        return (
          prioridad !== undefined &&
            ((item.cooperativa == prioridad.almacen.cooperativa.id_unnefar &&
              item.cooperativa_no_rebota == "1")
          ||
          // si el usuario es socio de la cooperativa que figura en la exclusion y farmacia_no_rebota=1 entonces no hay rebotes a otras cooperativas.
            (item.cooperativa != prioridad.almacen.cooperativa.id_unnefar &&
              item.farmacia_no_rebota=="1" && item.cooperativa == this.user.cooperativa.id_unnefar))
        );
      });
    },
    cerrarModalPedido(){
      this.modal_pedido=false;
    },
    ABRIR_MODAL_PEDIDO(valor){
      this.MOSTRAR_MODAL_CESTA(false);
      this.modal_pedido=valor;
    }
  },
};
</script>

<style lang="scss" scoped>
#search-view {
  .search-view-fixed {
    position: relative;
    width: 100%;
    z-index: 6000;
    background-color: #f7f8fc;
  }
  .search-view-header {
    background-color: #fff;
  }

  .search-view-results {
    // margin-top: 210px;
  }

  .search-view-filters {
    margin-top: -30px;
  }
  .search-view-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 30px;
  }

  .search-view-container_header {
    min-height: 180px;
  }

  .search-view-paginator {
    width: 100%;
    text-align: center;
    border: 1px #000000;
  }

  .search-background {
    position: relative;
    .search-select-input {
      width: 100%;
      display: flex;
      align-items: center;
      background: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
      .input-custom,
      .select-custom {
        box-shadow: none;
        background: none;
      }
      .input-custom {
        position: relative;
        width: calc(100%);
        flex-basis: calc(100%);
      }
    }
  }

  #search-view-btn-load-more {
    text-align: center;
    margin: 0 auto;
  }
}
</style>
